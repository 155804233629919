import React from "react";
import { setup, createInstance } from "@loomhq/record-sdk";
import { isSupported } from "@loomhq/record-sdk/is-supported";
import { oembed } from "@loomhq/loom-embed";
import { IconButton, Button, Box, Container, Grid, Paper, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, styled, CircularProgress, Alert, Snackbar, TableContainer, TableCell, TableBody, Table, TableRow, TableHead } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Excalidraw, exportToBlob } from "@excalidraw/excalidraw";
import { useState, useEffect } from 'react';
import Vapi from '@vapi-ai/web';
import axios from 'axios';
import io from 'socket.io-client';
import SyncIcon from '@mui/icons-material/Sync';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StopIcon from '@mui/icons-material/Stop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Avatar from '@mui/material/Avatar';
import { RetellWebClient } from "retell-client-js-sdk";
import ZoomVideo from '@zoom/videosdk';
import ReactGA from "react-ga4";
import Logo from './wayfaster-icon-bg.svg';
import Soundbar from './soundbar.svg';
import Listening from './Listening.svg';

import posthog from 'posthog-js'

const _ = require('lodash');

ReactGA.initialize([
  {
    trackingId: "G-KEJZXKN80Q",
  },
]);
ReactGA.send({ hitType: "pageview", page: "/", title: "hit" });

// TODO: move to process.env.DEEPGRAM_API_KEY
const vapi = new Vapi('c6a21d89-0ffd-45b2-9ad4-28a1fd2708f8')
var callId;
var socket;
var client = ZoomVideo.createClient();
var stream;
var sessionLength;
var introDone = false;

const retellWebClient = new RetellWebClient();

var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '6319e72d-0dc8-4bbc-86cc-09d1701df65d' // prod
var env = String(process.env.REACT_APP_ENV)

const PUBLIC_APP_ID = process.env.REACT_APP_LOOM_PUBLIC_APP_ID
const BUTTON_ID = "loom-record-sdk-button";

// RETELL ENDPOINTS
var retellApiEndpoint = 'https://retell-main-proxy-69440b772c45.herokuapp.com'
var retellWsEndpoint = 'wss://retell-main-proxy-69440b772c45.herokuapp.com'
// var retellAgentId = process.env.REACT_APP_RETELL_AGENT_ID // start with dev
var retellOnboardAgentId = process.env.REACT_APP_RETELL_ONBOARD_AGENT_ID

// Voice agent IDs
var defaultVoiceId = '11labs-Jason'
var RETELL_AGENT_ID_JASON = process.env.REACT_APP_RETELL_AGENT_ID_JASON_PRODUCTION
var RETELL_AGENT_ID_DOROTHY = process.env.REACT_APP_RETELL_AGENT_ID_DOROTHY_PRODUCTION
var RETELL_AGENT_ID_NOVA = process.env.REACT_APP_RETELL_AGENT_ID_NOVA_PRODUCTION
var RETELL_AGENT_ID_ANTHONY = process.env.REACT_APP_RETELL_AGENT_ID_ANTHONY_PRODUCTION
var RETELL_AGENT_ID_LILY = process.env.REACT_APP_RETELL_AGENT_ID_LILY_PRODUCTION
var RETELL_AGENT_ID_MARISSA = process.env.REACT_APP_RETELL_AGENT_ID_MARISSA_PRODUCTION
var RETELL_AGENT_ID_BRIAN = process.env.REACT_APP_RETELL_AGENT_ID_BRIAN_PRODUCTION
var RETELL_AGENT_ID_KATE = process.env.REACT_APP_RETELL_AGENT_ID_KATE_PRODUCTION
var RETELL_AGENT_ID_FABLE = process.env.REACT_APP_RETELL_AGENT_ID_FABLE_PRODUCTION
var RETELL_AGENT_ID_PAUL = process.env.REACT_APP_RETELL_AGENT_ID_PAUL_PRODUCTION
var RETELL_AGENT_ID_JOE = process.env.REACT_APP_RETELL_AGENT_ID_JOE_PRODUCTION
var RETELL_AGENT_ID_ADRIAN = process.env.REACT_APP_RETELL_AGENT_ID_ADRIAN_PRODUCTION
var RETELL_AGENT_ID_ZURI = process.env.REACT_APP_RETELL_AGENT_ID_ZURI_PRODUCTION
var RETELL_AGENT_ID_CHLOE = process.env.REACT_APP_RETELL_AGENT_ID_CHLOE_PRODUCTION
var RETELL_AGENT_ID_MAX = process.env.REACT_APP_RETELL_AGENT_ID_MAX_PRODUCTION
var RETELL_AGENT_ID_JULIA = process.env.REACT_APP_RETELL_AGENT_ID_JULIA_PRODUCTION
var RETELL_AGENT_ID_SUSAN = process.env.REACT_APP_RETELL_AGENT_ID_SUSAN_PRODUCTION
var RETELL_AGENT_ID_KATHRINE = process.env.REACT_APP_RETELL_AGENT_ID_KATHRINE_PRODUCTION
var RETELL_AGENT_ID_NINA = process.env.REACT_APP_RETELL_AGENT_ID_NINA_PRODUCTION
var RETELL_AGENT_ID_MYRA = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_PRODUCTION
var RETELL_AGENT_ID_PAOLA = process.env.REACT_APP_RETELL_AGENT_ID_PAOLA_PRODUCTION
var RETELL_AGENT_ID_EVIE = process.env.REACT_APP_RETELL_AGENT_ID_EVIE_PRODUCTION
var RETELL_AGENT_ID_JAMES = process.env.REACT_APP_RETELL_AGENT_ID_JAMES_PRODUCTION
var RETELL_AGENT_ID_RYAN = process.env.REACT_APP_RETELL_AGENT_ID_RYAN_PRODUCTION
var RETELL_AGENT_ID_MIA = process.env.REACT_APP_RETELL_AGENT_ID_MIA_PRODUCTION

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
  retellApiEndpoint = 'https://staging-core-voice-api-2ac3e4de69f0.herokuapp.com'

  RETELL_AGENT_ID_JASON = process.env.REACT_APP_RETELL_AGENT_ID_JASON_STAGING
  RETELL_AGENT_ID_DOROTHY = process.env.REACT_APP_RETELL_AGENT_ID_DOROTHY_STAGING
  RETELL_AGENT_ID_NOVA = process.env.REACT_APP_RETELL_AGENT_ID_NOVA_STAGING
  RETELL_AGENT_ID_ANTHONY = process.env.REACT_APP_RETELL_AGENT_ID_ANTHONY_STAGING
  RETELL_AGENT_ID_LILY = process.env.REACT_APP_RETELL_AGENT_ID_LILY_STAGING
  RETELL_AGENT_ID_MARISSA = process.env.REACT_APP_RETELL_AGENT_ID_MARISSA_STAGING
  RETELL_AGENT_ID_BRIAN = process.env.REACT_APP_RETELL_AGENT_ID_BRIAN_STAGING
  RETELL_AGENT_ID_KATE = process.env.REACT_APP_RETELL_AGENT_ID_KATE_STAGING
  RETELL_AGENT_ID_FABLE = process.env.REACT_APP_RETELL_AGENT_ID_FABLE_STAGING
  RETELL_AGENT_ID_PAUL = process.env.REACT_APP_RETELL_AGENT_ID_PAUL_STAGING
  RETELL_AGENT_ID_JOE = process.env.REACT_APP_RETELL_AGENT_ID_JOE_STAGING
  RETELL_AGENT_ID_ADRIAN = process.env.REACT_APP_RETELL_AGENT_ID_ADRIAN_STAGING
  RETELL_AGENT_ID_ZURI = process.env.REACT_APP_RETELL_AGENT_ID_ZURI_STAGING
  RETELL_AGENT_ID_CHLOE = process.env.REACT_APP_RETELL_AGENT_ID_CHLOE_STAGING
  RETELL_AGENT_ID_MAX = process.env.REACT_APP_RETELL_AGENT_ID_MAX_STAGING
  RETELL_AGENT_ID_JULIA = process.env.REACT_APP_RETELL_AGENT_ID_JULIA_STAGING
  RETELL_AGENT_ID_SUSAN = process.env.REACT_APP_RETELL_AGENT_ID_SUSAN_STAGING
  RETELL_AGENT_ID_KATHRINE = process.env.REACT_APP_RETELL_AGENT_ID_KATHRINE_STAGING
  RETELL_AGENT_ID_NINA = process.env.REACT_APP_RETELL_AGENT_ID_NINA_STAGING
  RETELL_AGENT_ID_MYRA = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_STAGING
  RETELL_AGENT_ID_PAOLA = process.env.REACT_APP_RETELL_AGENT_ID_PAOLA_STAGING
  RETELL_AGENT_ID_EVIE = process.env.REACT_APP_RETELL_AGENT_ID_EVIE_STAGING
  RETELL_AGENT_ID_JAMES = process.env.REACT_APP_RETELL_AGENT_ID_JAMES_STAGING
  RETELL_AGENT_ID_RYAN = process.env.REACT_APP_RETELL_AGENT_ID_RYAN_STAGING
  RETELL_AGENT_ID_MIA = process.env.REACT_APP_RETELL_AGENT_ID_MIA_STAGING
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
  retellApiEndpoint = 'http://localhost:8080'
  retellWsEndpoint = 'ws://localhost:8080'

  RETELL_AGENT_ID_JASON = process.env.REACT_APP_RETELL_AGENT_ID_JASON
  RETELL_AGENT_ID_DOROTHY = process.env.REACT_APP_RETELL_AGENT_ID_DOROTHY
  RETELL_AGENT_ID_NOVA = process.env.REACT_APP_RETELL_AGENT_ID_NOVA
  RETELL_AGENT_ID_ANTHONY = process.env.REACT_APP_RETELL_AGENT_ID_ANTHONY
  RETELL_AGENT_ID_LILY = process.env.REACT_APP_RETELL_AGENT_ID_LILY
  RETELL_AGENT_ID_MARISSA = process.env.REACT_APP_RETELL_AGENT_ID_MARISSA
  RETELL_AGENT_ID_BRIAN = process.env.REACT_APP_RETELL_AGENT_ID_BRIAN
  RETELL_AGENT_ID_KATE = process.env.REACT_APP_RETELL_AGENT_ID_KATE
  RETELL_AGENT_ID_FABLE = process.env.REACT_APP_RETELL_AGENT_ID_FABLE
  RETELL_AGENT_ID_PAUL = process.env.REACT_APP_RETELL_AGENT_ID_PAUL
  RETELL_AGENT_ID_JOE = process.env.REACT_APP_RETELL_AGENT_ID_JOE
  RETELL_AGENT_ID_ADRIAN = process.env.REACT_APP_RETELL_AGENT_ID_ADRIAN
  RETELL_AGENT_ID_ZURI = process.env.REACT_APP_RETELL_AGENT_ID_ZURI
  RETELL_AGENT_ID_CHLOE = process.env.REACT_APP_RETELL_AGENT_ID_CHLOE
  RETELL_AGENT_ID_MAX = process.env.REACT_APP_RETELL_AGENT_ID_MAX
  RETELL_AGENT_ID_JULIA = process.env.REACT_APP_RETELL_AGENT_ID_JULIA
  RETELL_AGENT_ID_SUSAN = process.env.REACT_APP_RETELL_AGENT_ID_SUSAN
  RETELL_AGENT_ID_KATHRINE = process.env.REACT_APP_RETELL_AGENT_ID_KATHRINE
  RETELL_AGENT_ID_NINA = process.env.REACT_APP_RETELL_AGENT_ID_NINA
  RETELL_AGENT_ID_MYRA = process.env.REACT_APP_RETELL_AGENT_ID_MYRA
  RETELL_AGENT_ID_PAOLA = process.env.REACT_APP_RETELL_AGENT_ID_PAOLA
  RETELL_AGENT_ID_EVIE = process.env.REACT_APP_RETELL_AGENT_ID_EVIE
  RETELL_AGENT_ID_JAMES = process.env.REACT_APP_RETELL_AGENT_ID_JAMES
  RETELL_AGENT_ID_RYAN = process.env.REACT_APP_RETELL_AGENT_ID_RYAN
  RETELL_AGENT_ID_MIA = process.env.REACT_APP_RETELL_AGENT_ID_MIA
}

var agentMapping = {
  '11labs-Jason': RETELL_AGENT_ID_JASON,
  '11labs-Dorothy': RETELL_AGENT_ID_DOROTHY,
  'openai-Nova': RETELL_AGENT_ID_NOVA,
  '11labs-Anthony': RETELL_AGENT_ID_ANTHONY,
  '11labs-Lily': RETELL_AGENT_ID_LILY,
  '11labs-Marissa': RETELL_AGENT_ID_MARISSA,
  '11labs-Brian': RETELL_AGENT_ID_BRIAN,
  '11labs-Kate': RETELL_AGENT_ID_KATE,
  'openai-Fable': RETELL_AGENT_ID_FABLE,
  '11labs-Paul': RETELL_AGENT_ID_PAUL, 
  '11labs-Joe': RETELL_AGENT_ID_JOE,
  '11labs-Adrian': RETELL_AGENT_ID_ADRIAN,
  '11labs-Zuri': RETELL_AGENT_ID_ZURI,
  '11labs-Chloe': RETELL_AGENT_ID_CHLOE,
  '11labs-Max': RETELL_AGENT_ID_MAX,
  '11labs-Julia': RETELL_AGENT_ID_JULIA,
  '11labs-Susan': RETELL_AGENT_ID_SUSAN,
  '11labs-Kathrine': RETELL_AGENT_ID_KATHRINE,
  '11labs-Nina': RETELL_AGENT_ID_NINA,
  '11labs-Myra': RETELL_AGENT_ID_MYRA,
  '11labs-Paola': RETELL_AGENT_ID_PAOLA,
  '11labs-Evie': RETELL_AGENT_ID_EVIE,
  '11labs-James': RETELL_AGENT_ID_JAMES,
  '11labs-Ryan': RETELL_AGENT_ID_RYAN,
  '11labs-Mia': RETELL_AGENT_ID_MIA
}

// if(env === 'staging') {
//   proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
//   wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
//   vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
// } else if (env === 'development') {
//   proxyEndpoint = 'http://localhost:5555'
//   wsEndpoint = 'ws://localhost:5555'
//   vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
//   retellApiEndpoint = 'http://localhost:8080'
//   retellWsEndpoint = 'ws://localhost:8080'
// }

var stopInvoked = false;
const emptyInterviewObject = {
  unique_session_id: null,
  state: 'none',
  messages: [],
  context: [],
  mute: false,
  metadata: {
    interview_config: {
      title: '',
      questions: [{
        question: '',
        type: '',
        metadata: {}
      }]
    }
  },
  scorecard: [{ signal: 'test', feedback: 'test feedback', score: 6, asked_to_candidate: false}],
  scoring_pending: false,
  parrot_text_index: 0
}
const StateToCueMap = {
  'none': 'Get started by clicking on the "Start Interview" button above.',
  "FirstResponse": "Let Steve know you can hear him ok. He's a bit hard of hearing, so you may have to tell him twice.",
  "ProblemAffirmation": "Tell Steve you want to proceed if you feel comfortable with the problem.",
  "DrawOnWhiteboard": 'Complete the diagram on the whiteboard to solve the given problem. You can talk to Steve if you get stuck. Click the "get whiteboard feedback" button to get whiteboard feedback.'
}


const OnboardLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 4,
      backgroundColor: "#F4E132",
    },
  }))


// TODO: SWITCH BACK, only for staging testing
var interviewDuration = 600;
var globalInterviewType = 'system_design_event_ticketing'
var interviewerName = 'Steve'
const urlParams = new URLSearchParams(window.location.search);
const interviewTypeQueryParam = urlParams.get('interview_type');
if (interviewTypeQueryParam) {
  globalInterviewType = interviewTypeQueryParam
}
var allSysDesignParam = urlParams.get('all_sys_design');
if(!allSysDesignParam) {
  allSysDesignParam = 'false'
}

// will tell us if we're in a dedicated session or not (a session is a pre-defined interview basically tied to an account)
const sessionId = urlParams.get('session');
if(sessionId) {
  globalInterviewType = ''
}

if(globalInterviewType === 'eve') {
  interviewerName = 'Isabelle'
}

const onboardCopy = [
  { prompt: <>
      <p style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '400', color: '#8A8F8F', fontFamily: 'PPNeueMontreal'}}>
        Wayfaster AI is a voice interview that allows you to be interviewed in a bias-free way only on your skills, faster than a human.        
      </p>
    </>,
    flag: "start",
    heading: <><p style={{ fontFamily: 'PPNeueMontreal', color: '#151F20', fontSize: '28px', lineHeight: '28px', fontWeight: '600' }}>Get Started</p></>,
    action: 'Got it'
  },
  { prompt: <>
      <p style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '400', color: '#8A8F8F', fontFamily: 'PPNeueMontreal'}}>
        <>
        Before we start, we'd like you to meet Steve. Steve will help guide you through the interview process!
        </>
      </p>
    </>,
    note: <div style={{ background: '#FFFBEB', padding: '10px', fontWeight: 600 }}>
      No pressure, just a friendly chat to get to know each other!  
    </div>,
    flag: "mic",
    extra: 'No pressure, just a friendly chat to get to know each other!',
    heading: <><p style={{ fontFamily: 'PPNeueMontreal', color: '#151F20', fontSize: '28px', lineHeight: '28px', fontWeight: '600' }}>
      Say Hello to Steve <img src={Logo} />
    </p></>,
    practiceChat: true,
    action: "Start a friendly chat with Steve",
    actionFn: function() {
      // create intro call 
      console.log('register intro call')
      return fetch(`${retellApiEndpoint}/register-intro-call`, {
        method: 'POST',
        body: JSON.stringify({
          'session_id': sessionId
        })
      })
      .then((registerRes) => {
        console.log('res', registerRes)
        registerRes.json()
        .then((jsonRegisterRes) => {
          console.log(jsonRegisterRes)
          retellWebClient.startCall({
            accessToken: jsonRegisterRes.access_token,
            enableUpdate: false
          })
          .then(() => {
            console.log('conversation has started')
          })
          .catch((err) => {
            console.error(err)
          })
        })
        .catch((jsonErr) => {
          console.error(jsonErr)
        })
      })
      .catch((err) => {
        console.error('err', err)
      })
    }
  },
  {
    interviewScreen: true,
  },
  { 
    heading: <><p style={{ fontFamily: 'PPNeueMontreal', color: '#151F20', fontSize: '28px', lineHeight: '28px', fontWeight: '600' }}>
    Start the Interview
  </p></>,
    prompt: <p style={{ fontSize: '16px', lineHeight: '24px', fontWeight: '400', color: '#8A8F8F', fontFamily: 'PPNeueMontreal'}}>
      Now it's time for a real conversation. Our friend Steve will talk to you about your background and experience. You will be asked to turn on your camera and microphone.
    </p>,
    flag: "end",
    action: "Let's go",
  }]


const App = () => {
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [interviewStarted, setInterviewStarted] = useState(false); 
    const [onboardStep, setOnboardStep] = React.useState(0);
    const [onboardProgress, setOnboardProgress] = React.useState(25);
    const [onboardPrompt, setOnboardPrompt] = React.useState(onboardCopy[0].prompt);
    const [onboardNote, setOnboardNote] = React.useState(onboardCopy[0].note);
    const [onboardAction, setOnboardAction] = useState(onboardCopy[0].action);
    const [onboardHeading, setOnboardHeading] = useState(onboardCopy[0].heading)
    const [waitlistIsOpen, setWaitlistIsOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [interview, setInterview] = useState(emptyInterviewObject);
    const [openHelp, setOpenHelp] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [loadingDiagramFeedback, setLoadingDiagramFeedback] = useState(false);
    const [micEnabled, setMicEnabled] = useState(false);
    const [waitingEnable, setWaitingEnable] = useState(false);
    const [waitingDemo, setWaitingDemo] = useState(false);
    const [visualState, setVisualState] = useState('');
    const [firstMobileScreenDone, setFirstMobileScreenDone] = useState(false);
    const [timerElapsed, setTimerElapsed] = React.useState(0);
    const [showGracefulExitMsg, setShowGracefulExitMsg] = useState(false)
    const [showStopInterviewConfirmation, setShowStopInterviewConfirmation] = useState(false)
    const [showInterviewEndedMidway, setShowInterviewEndedMidway] = useState(false)
    const [closeOnboarding, setCloseOnboarding] = useState(false)
    const [sessionLoading, setSessionLoading] = useState(sessionId ? true : false)

    // one or the other
    const [scorecard, setScorecard] = useState([])
    const [scorecards, setScorecards] = useState([])

    // Waitlist
    const [subscriptionSuccessSnackOpen, setSubscriptionSuccessSnackOpen] = React.useState(false);
    const [subscriptionFailSnackOpen, setSubscriptionFailSnackOpen] = React.useState(false);
    const [session, setSession] = useState(null)
    const [submission, setSubmission] = useState('')
    const [feedback, setFeedback] = useState('')
    const [feedbackPending, setFeedbackPending] = useState(false)
    const [fullSession, setFullSession] = useState(null)

    // loom
    const [videoHTML, setVideoHTML] = useState("");
    useEffect(() => {
      async function setupLoom() {
        console.log('setupLoom')
        const { supported, error } = await isSupported();

        if(!session) {
          return
        }

        if(session && session.videoRecorder !== 'loom') {
          console.log('not loom', session.videoRecorder)
          return
        }

        if (!supported) {
          console.warn(`Error setting up Loom: ${error}`);
          return;
        }

        const button = document.getElementById(BUTTON_ID);
        if (!button) {
          return;
        }

        const {configureButton } = await createInstance({ 
          mode: 'standard',
          publicAppId: PUBLIC_APP_ID,
          config: {
            allowedRecordingTypes: ['cam'],
            defaultRecordingType: 'cam'
          }
        })

        const sdkButton = configureButton({ element: button });

        sdkButton.on("recording-start", async () => {
          console.log('setting mic enabled')
          navigator.mediaDevices.getUserMedia({ audio: true, video: true })
          .then(function(stream) {
            setMicEnabled(true);
            handleStartInterview();
          })
          .catch(function(err) {
            console.log('Microphone permission denied');
          });        
        });

        sdkButton.on("recording-complete", async(video) => {
          const { html } = await oembed(video.sharedUrl, { width: 400 });
          // setVideoHTML(html);
          console.log('finished')
          // store this url to the interview
          console.log('session', session)
          axios.post(proxyEndpoint + '/interview_sessions/' + session._id, {
            interview_session: {
              ...fullSession,
              videoRecordingUrl: video.sharedUrl
            }
          })
          .then(response => {
            console.log('interview updated with url', response)
          })
          .catch(err => {
            console.error(err);
          });          
        })
      }

      if(fullSession && interview){
        console.log('session', session)
        setupLoom();
      }
    }, [micEnabled, setMicEnabled, session, fullSession, onboardStep]);    
    const [introTitle, setIntroTitle] = useState('')
    const [useIntroTitle, setUseIntroTitle] = useState(false)
    const [introMsgCount, setIntroMsgCount] = useState(0)
    const [transcript, setTranscript] = useState([])

    // Initialize the SDK
    useEffect(() => {
      retellWebClient.on("call_started", () => {
        console.log("call started");
        setVisualState('connected')
      });
      
      retellWebClient.on("call_ended", () => {
        console.log("call ended");
        setVisualState('disconnected')
      });
      
      // When agent starts talking for the utterance
      // useful for animation
      retellWebClient.on("agent_start_talking", () => {
        console.log("agent_start_talking");
        setVisualState('speaking')
      });
      
      // When agent is done talking for the utterance
      // useful for animation
      retellWebClient.on("agent_stop_talking", () => {
        console.log("agent_stop_talking");
        setVisualState('listening')
        if(!introDone) {
          if(transcript === undefined) {
            console.log('no transcript yet')
            return
          }
  
          // check if four AI messages are done
          var letsGoMsgFound = false
          transcript.map((msg) => {
            if(msg.role === 'agent' && (msg.content.includes('real interview') || msg.content.includes('onboarding')) ) {
              letsGoMsgFound = true
            }
            return 0
          })
  
          if(letsGoMsgFound) {
            console.log('letsGoMsgFound')
            setTimeout(() => {
              setUseIntroTitle(false)
              setInterviewStarted(false)
              setTimer(sessionLength)
              retellWebClient.stopCall()    
              introDone = true     
              setVisualState('')
              advanceOnboardStep() 
            }, 3000)
          } 
        }       
      });
      
      // Real time pcm audio bytes being played back, in format of Float32Array
      // only available when emitRawAudioSamples is true
      retellWebClient.on("audio", (audio) => {
        // console.log(audio);
      });
      
      // Update message such as transcript
      retellWebClient.on("update", (update) => {
        setTranscript(update.transcript)
      });
      
      retellWebClient.on("metadata", (metadata) => {
        // console.log(metadata);
      });
      
      retellWebClient.on("error", (error) => {
        console.error("An error occurred:", error);
        // Stop the call
        retellWebClient.stopCall();
      });      
    }, [session, setUseIntroTitle, setInterviewStarted, setTranscript, transcript, useIntroTitle]);    

    if(sessionId && session === null) {
      axios.post(`${proxyEndpoint}/session_details`,{
        sessionId: sessionId
      })
      .then((response) => {
        setSession(response.data)
        
        // posthog identify with candidate information
        // use session id as unique identifier
        let candidate_metadata = response.data.candidate_metadata;
        posthog.identify(sessionId, {
          "email": candidate_metadata.email,
          "given_name": candidate_metadata.first_name,
          "family_name": candidate_metadata.last_name,
          "call_type": candidate_metadata.call_type,
          "phone": candidate_metadata.phone,

          "user_type": "candidate",

          "unique_session_id": sessionId,
          "interview_config_id": response.data.interview_config_id,
          "interview_user_id": response.data.user_id,
          "interview_team_id": response.data.team_id,
        })

        setSessionLoading(false)
        var length = response.data.length
        if(length !== undefined) {
          if (typeof length === 'string') {
            length = parseInt(length);
          }

          setTimer(length * 60)
          sessionLength = length * 60
          console.log(sessionLength)
          axios.post(`${proxyEndpoint}/session`,{
            id: response.data._id,
            sessionId: sessionId
          })
          .then((response) => {
            console.log(response.data)
            setFullSession(response.data)
          })
          .catch((err) => {
            console.log('err on getting full session details')
          })
        }
      })
      .catch((error) => {
        console.error('Error fetching session details:', error);
        setSessionLoading(false)
      });
    }

    const registerCall = (agentId, interviewType, sessionId) => {      
      try {
        let clientType = 'desktop'
        if (isMobile) {
          clientType = 'mobile'
        }
      
        // Replace with your server url
        return fetch(
          `${retellApiEndpoint}/register-call`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              agent_id: agentId,
              client_type: clientType,
              interview_type: interviewType,
              session_id: sessionId,
              sample_rate: 16000,
            }),
          },
        )
        .then((response) => {
          return response.json()
          .then((jsonData) => {
            return jsonData
          })
          .catch((err) => {
            throw new Error(err)
          })
        })
        .catch((err) => {
          console.log(err)
          throw new Error(err)
        })
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    }    

    const handleOpenWaitlist = () => {
      setWaitlistIsOpen(true);
    }

    const handleCloseWaitlist = () => {
      setWaitlistIsOpen(false);
    }

    const handleCloseSuccessSnack = () => {
      setSubscriptionSuccessSnackOpen(false);
    }

    const handleCloseFailSnack = () => {
      setSubscriptionFailSnackOpen(false);
    }

    const validateEmail = (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }    

    const handleJoinWaitlist = async () => {
      const emailValid = validateEmail(email)
      if(!emailValid) {
        setSubscriptionFailSnackOpen(true)
        return
      }

      try {
        const response = await axios.post(proxyEndpoint + '/join_waitlist', {
          subscription: { email: email }
        });
        if(!response.data.result) {
          setSubscriptionFailSnackOpen(true);  
        } else {
          handleCloseWaitlist();
          setSubscriptionSuccessSnackOpen(true);
          setWaitlistIsOpen(false);
        }
      } catch (error) {
        setSubscriptionFailSnackOpen(true);
      }
      
    }

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    const handleCloseSubscriptionFailSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSubscriptionFailSnackOpen(false);
    };

    const handleCloseSubscriptionSuccessSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSubscriptionSuccessSnackOpen(false);
    };

    // Tabs
    const [tabValue, setTabValue] = React.useState(0);

    // Mobile home screen mode
    const [startMobileDemo, setStartMobileDemo] = React.useState(false);

    const handleChangeTab = (event, newValue) => {
      setTabValue(newValue);
    };


    // Material UI responsive layout settings
    const theme = useTheme();
    const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const excalidrawStyle = {
        minHeight: '60vh',
        width: '100%',
        background: '#050505', // Change the background colo
    };
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // UI
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      fontFamily: 'figtree, sans-serif', // Set the font family to "figtree"
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginRight: '20px'
    }));

    // Timer
    const [timer, setTimer] = React.useState(interviewDuration);

    useEffect(() => {
      let interval = null;
      if (interviewStarted) {
        interval = setTimeout(() => {
          if(timer > 0 && !showGracefulExitMsg) {
            setTimer(timer => timer - 1)
            setTimerElapsed(timerElapsed => timerElapsed + 1)
          }
        }, 1000);
      }

      return () => {
          if (interval !== null && timer <= 0) {
              clearTimeout(interval);
          }
      };
  }, [interviewStarted, timer, useIntroTitle]);
    // Help button
    const handleHelpOpen = () => {
      setOpenHelp(true);
    }
  
    const handleHelpClose = () => {
      setOpenHelp(false);
    };

    const handleStartMobileDemo = () => {
      setStartMobileDemo(true);
    }

    if(excalidrawAPI !== null && !updated) {
        fetch('prefill.excalidraw.json')
        .then(response => response.json())
        .then(data => {
          excalidrawAPI.updateScene({elements: data.elements, appState: data.appState});
          excalidrawAPI.scrollToContent(data.elements, {
              fitToContent: true,
              animate: false,
          });
          setUpdated(true);
          const appState = excalidrawAPI.getAppState();
          const elements = excalidrawAPI.getSceneElements();
          if (!elements || !elements.length) {
              return
          }          

          exportToBlob({
              elements,
              appState: {
                  ...appState,
                  exportWithDarkMode: false,
              },
              files: excalidrawAPI.getFiles(),
              maxWidthOrHeight: 1000
          })
          .catch((error) => {
              console.error('Error:', error);
          });
        })
    }

    const initRetellWebsocketConnection = (registerRes) => {
      console.log('initRetellWebsocketConnection', registerRes, registerRes.call_id, registerRes.access_token)
      var socket = new WebSocket(retellWsEndpoint + '/register-candidate-client/' + registerRes.call_id)
      socket.onmessage = function(event) {
        const jsonEvent = JSON.parse(event.data)
        if(jsonEvent.type === 'update') {
          const jsonData = jsonEvent.data
          setInterview(jsonData)
        } else if (jsonEvent.type === 'done') {
          handleStopRetellInterview(false) 
        } else if (jsonEvent.type === 'register') {
          console.log(registerRes)
          retellWebClient.startCall({
            accessToken: registerRes.access_token,
            sampleRate: registerRes.sample_rate,
            enableUpdate: false
          })
          .then(() => {
            console.log('conversation has started')
          })
          .catch((err) => {
            console.error(err)
            retellWebClient.startCall({
              accessToken: registerRes.access_token,
              sampleRate: registerRes.sample_rate,
              enableUpdate: false
            })
            .then(() => {
              console.log('conversation has started (2nd try)')
            })
            .catch((err) => {
              console.error(err)
              retellWebClient.startCall({
                accessToken: registerRes.access_token,
                sampleRate: registerRes.sample_rate,
                enableUpdate: false
              })
              .then(() => {
                console.log('conversation has started (3rd try)')
              })
              .catch((err) => {
                console.error(err)
                retellWebClient.startCall({
                  accessToken: registerRes.access_token,
                  sampleRate: registerRes.sample_rate,
                  enableUpdate: false
                })
                .then(() => {
                  console.log('conversation has started (4th try)')
                })
                .catch((err) => {
                  console.error(err)
                }) 
              }) 
            })                  
          })                  
        }
      }      

      return socket
    }

    const initWebsocketConnection = (callId, interviewTypeParam, sessionId) => {
      let clientType = 'desktop'
      if (isMobile) {
        clientType = 'mobile'
      }

      console.log('initWebsocketConnection')
      console.log(globalInterviewType);
      console.log('sessionId', sessionId)

      const socket = io(wsEndpoint, {
        withCredentials: true,
        transports: ["websocket"]        
      });
      socket.on('connect', () => {
        console.log('Websocket connected');
      });

      socket.on('disconnect', () => {
        console.log('Websocket disconnected');
      });

      socket.emit('register', {
        callId: callId,
        clientType: clientType,
        interviewType: interviewTypeParam,
        sessionId: sessionId,
      });
      socket.on('update', (interview) => {
        console.log('update', interview)
        var tmpInterview = interview
        if (typeof tmpInterview === 'string') {
          tmpInterview = JSON.parse(interview);
        }
        console.log(tmpInterview.scorecard)
        if(tmpInterview.metadata.callId === callId) {
          setInterview(tmpInterview);
        }
      });
      socket.on('done', (data) => {
        console.log('done', data)
        if(data.callId === callId) {
          console.log('done true', interview)
          handleStopInterview()
        }
      })
      socket.on('parrot', (res) => {
        console.log('par', res.callId)
        if(res.callId === callId) {
          console.log('parroting')
          vapi.send({
            type: "add-message",
            message: {
              role: "system",
              content: "parrot_: " + res.res,
            },
          });        
        }
      })

      return socket;
    }

    const handleStartInterview = (interviewTypeParam) => {
      console.log('handleStartInterview')
      setInterviewStarted(true);
      setCloseOnboarding(true);
      var interviewType = 'system_design_event_ticketing'
      var sessionId = null
      if(interviewTypeParam !== '') {
        interviewType = interviewTypeParam
        globalInterviewType = interviewType
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const interviewTypeQueryParam = urlParams.get('interview_type');
        if (interviewTypeQueryParam) {
          interviewType = interviewTypeQueryParam;
          globalInterviewType = interviewType
        }
      }

      if(session !== null) {
        interviewTypeParam = null
        sessionId = session.unique_session_id
      }

      const voiceInfraProvider = process.env.REACT_APP_VOICE_INFRA_PROVIDER;
      if(voiceInfraProvider === 'vapi') {
        if(interviewTypeParam === 'eve') {
          vapiEnvId = 'df16d937-aa79-4202-8304-49091a6b92f7'
        }

        vapi.start(vapiEnvId)
        .then((data) => {
          // i assume ID is callId
          callId = data['id']
          if(socket === undefined) {
              socket = initWebsocketConnection(callId, interviewTypeParam, sessionId);
          }                  
        })
        .catch((err) => {console.error(err)})

        vapi.on('speech-start', () => {
            console.log('Speech has started');
            setVisualState('speaking');
        });
        
        vapi.on('speech-end', () => {
            console.log('Speech has ended');
            setVisualState('listening');
        });
        
        vapi.on('call-start', () => {
            console.log('Call has started');            
        });
        
        vapi.on('call-end', () => {
            console.log('Call has stopped');
        });
        
        vapi.on('volume-level', (volume) => {
        });
        
        // Function calls and transcripts will be sent via messages
        vapi.on('message', (message) => {
            console.log(message);
        });
        
        vapi.on('error', (e) => {
            console.error(e)
        });   
      } else if(voiceInfraProvider === 'retell') {
        // add voice check and mapping
        let retellAgentId = session.voice ? agentMapping[session.voice] : agentMapping[defaultVoiceId]
        console.log(retellAgentId, session.voice)
        registerCall(retellAgentId, interviewTypeParam, sessionId)
        .then((res) => {
          console.log('register call res', res)
          if(res.call_id) {
            callId = res.call_id
            console.log(res, 'starting conversation')
            socket = initRetellWebsocketConnection(res)
            if(session && (!session.videoRecorder || session.videoRecorder === 'builtin')) {
              fetch(proxyEndpoint + '/get_video_jwt', {
                body: JSON.stringify({
                  callId: callId
                }), 
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then((data) => {
                data.json().then((jsonData) => {
                  console.log('jwt json res', jsonData)
  
                  client.init('en-US', 'Global', { patchJsMedia: true }).then(() => {
                    client
                      .join(callId, jsonData.jwt, 'candidate')
                      .then(() => {
                        stream = client.getMediaStream()
                        stream.startVideo()
                        stream.startAudio()
                        const recordingClient = client.getRecordingClient()
                        recordingClient.startCloudRecording()
                        .then(() => {
                          console.log('recording')
                        })
                        .catch((err) => {
                          console.error(err)
                        })
                      })
                  })                                  
                })
              })
              .catch((err) => {
                console.error(err)
              })
            }                
          }
        })
        .catch((err) => {
          console.error(err)
        })
      }
    }

    const handleStopInterview = () => {
      console.log('handleStopInterview')
      // get scorecard
      if(stopInvoked) {
        return 
      }        
      stopInvoked = true          
      vapi.send({
        type: "add-message",
        message: {
          role: "system",
          content: "parrot_: That is the end of the interview. Thanks so much for taking the time to chat with us, and we will get back to you shortly!",
        },
      });          
      
      console.log('making request')
      axios.post(`${proxyEndpoint}/structured_outputs`, { callId: callId })                  
      .then((response) => {
          console.log(response.data)
      })
      .catch((error) => {          
          console.error('Error fetching structured outputs:', error);
      });

      axios.post(`${proxyEndpoint}/scorecard`, { callId: callId })                  
      .then((response) => {
          console.log(response.data)
      })
      .catch((error) => {          
          console.error('Error fetching scorecard:', error);
      });

      axios.post(`${proxyEndpoint}/summary`, { callId: callId })                  
      .then((response) => {
          console.log(response.data)
      })
      .catch((error) => {          
          console.error('Error fetching summary:', error);
      });        

      axios.post(`${proxyEndpoint}/recording`, { callId: callId })                  
      .then((response) => {
          console.log(response.data)
      })
      .catch((error) => {          
          console.error('Error fetching recording:', error);
      });               

      setTimeout(() => {
        vapi.stop()
        if(socket != null) {
          socket.close();
          socket = null;
        }
        // setInterview(emptyInterviewObject)                  
      }, 10000) 
    }

    const handleStopRetellInterview = (userClick) => {
      // get scorecard
      if(stopInvoked) {
        return 
      }        
      stopInvoked = true     
      if(userClick) {
        retellWebClient.stopCall()
        if(socket != null) {
          socket.close();
          socket = null;
        }        
        setShowInterviewEndedMidway(true)
      } else {
        setShowGracefulExitMsg(true)
        setTimeout(() => {
          retellWebClient.stopCall()
          if(socket != null) {
            socket.close();
            socket = null;
          }
        }, 10000) 
      }
      
      if(session && session.videoRecorder === 'builtin') {
        const recordingClient = client.getRecordingClient()
        recordingClient.stopCloudRecording()
        .then(() => {
          console.log('stopped')
          stream.stopVideo()
        })        
        .catch((err) => {
          console.error(err)
        })
      }   
      
      if(session && session.videoRecoder === 'loom') {
        console.log('stop loom recording')
      }
    
      axios.post(`${proxyEndpoint}/structured_outputs`, { callId: callId })                  
      .then((response) => {
          console.log(response.data)
      })
      .catch((error) => {          
          console.error('Error fetching structured outputs:', error);
      });

      axios.post(`${proxyEndpoint}/scorecard`, { callId: callId })                  
      .then((response) => {
        console.log('scorecard', response.data)
        if(response.data.type === 'multiple') {
          setScorecards(response.data.results)
        } else {
          setScorecard(response.data.results)
        }
      })
      .catch((error) => {          
          console.error('Error fetching scorecard:', error);
      });

      axios.post(`${proxyEndpoint}/recording`, { callId: callId })                  
      .then((response) => {
          console.log(response.data)
      })
      .catch((error) => {          
          console.error('Error fetching recording:', error);
      });     
      
      axios.post(`${proxyEndpoint}/summary`, { callId: callId })                  
      .then((response) => {
          console.log(response.data)
      })
      .catch((error) => {          
          console.error('Error fetching summary:', error);
      });                
    }    

    const handleSubmitDiagramFeedback = () => {
      console.log('handleSubmitDiagramFeedback')
      setLoadingDiagramFeedback(true);
      const buffering_reses = [
        'Hang on, taking a look at the whiteboard', 
        'One sec, taking a look', 
        'Looking, give me one second.',
        "Bear with me, I'm perusing the whiteboard details.",
        "Just a tick, I'm eyeing the whiteboard content.",
        'Allow me a brief pause to scan the whiteboard.',
        'I need a short moment to digest the whiteboard info.',
        'Let me take a swift glance at the whiteboard.',
        "I'm diving into the whiteboard, hold on.",
        'A quick study of the whiteboard is underway.',
        "I'm zooming in on the whiteboard, stand by.",
        'Peering into the whiteboard, just a moment.',
        "I'm deciphering the whiteboard scribbles, hang tight."
      ];
      const buffering_res = buffering_reses[Math.floor(Math.random() * buffering_reses.length)];       
      
      if (!excalidrawAPI) {
          return
      }
      const elements = excalidrawAPI.getSceneElements();
      if (!elements || !elements.length) {
          return
      }

      const appState = excalidrawAPI.getAppState();

      exportToBlob({
          elements,
          appState: {
              ...appState,
              exportWithDarkMode: false,
          },
          files: excalidrawAPI.getFiles(),
          maxWidthOrHeight: 1000
      })
      .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
              const base64data = reader.result;
              fetch(`${proxyEndpoint}/get_diagram_feedback`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ image: base64data, callId: callId })
              })
              .then((response) => {
                  setLoadingDiagramFeedback(false);
                  response.json()
                  .then((jsonRes) => {
                    setFeedback(jsonRes.feedback)
                  })
                  .catch((err) => {console.error(err)})
              })
              .catch((err) => {console.error(err)})
          }
      }) 
    }

    const renderContext = () => {
      return interview.context.map((message, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
            <Avatar sx={{ bgcolor: 'white', color: 'black', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
            <Typography variant="body2" sx={{ fontFamily: 'PPNeueMontreal', color: 'black', }}>{message}</Typography>
        </div>
      ))      
    }

    const renderFeedback = () => {
      if(feedback !== '') {
        return (
          <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
            <Typography variant="body2" sx={{ fontFamily: 'PPNeueMontreal', color: 'white', fontWeight: 700 }}>
              {feedback}
            </Typography>
          </div>                   
        )
      } else {
        return (
          <></>
        )
      }
    }

    function secondsToHumanReadableTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      const formattedTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    
      return formattedTime;
    }    

    const handleToggleMute = () => {
      console.log('handleToggleMute')
      socket.emit('mute', callId);
    }

    const enableMic = () => {
      setWaitingEnable(true);
      navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then(function(stream) {
        setWaitingEnable(false);
        setMicEnabled(true);
      })
      .catch(function(err) {
        setWaitingEnable(false);
        console.log('Microphone permission denied');
      });
    }

    const handleSubmitText = () => {
      console.log('handleSubmitText', submission)
      setFeedbackPending(true)
      fetch(`${retellApiEndpoint}/text_input_submission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          call_id: callId,
          submission: submission,
        }),
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setFeedbackPending(false)
        setFeedback(data.feedback);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }

    const getDisplayValue = (component) => {
      if(component === 'feedback') {
        if(current_question && current_question.type === 'text_input') {
          return true
        } else {
          return false
        }
      }
    }

    const renderEnableMicBtn = () => {
      if(session && (!session.videoRecorder || session.videoRecorder === 'builtin')) {
        return (
          <button className="btn" autoFocus disabled={micEnabled} onClick={() => {enableMic()}}>
          {micEnabled ? 'Microphone and Camera Enabled' : 'Enable Microphone and Camera'}
          </button>                                 
        )
      } else {
        return (
          <button id={BUTTON_ID} className="btn" autoFocus disabled={micEnabled}>
            {micEnabled ? 'Microphone and Camera Enabled' : 'Enable Microphone and Camera'}
          </button>                                
        )
      }
    }

    const renderLetsGoBtn = () => {
      if(session && (!session.videoRecorder || session.videoRecorder === 'builtin')) {
        return (
          <>
          {onboardAction === "Let's go" ?
            <Button className="btn-main" onClick={() => {
              enableMic();
              handleStartInterview(globalInterviewType);
            }}>
              {onboardAction}
            </Button>
            :                        
            <Button className="btn-main" onClick={() => {advanceOnboardStep()}}>
              {onboardAction}
            </Button>
          }      
          </>
        )
      } else {
        return (
          <>
          {onboardAction === "Let's go" ?
            <Button className="btn-main" id={BUTTON_ID}>
              {onboardAction}
            </Button>
            :                        
            <Button className="btn-main" onClick={() => {advanceOnboardStep()}}>
              {onboardAction}
            </Button>
          }      
          </>                                      
        )
      }
    }    
    
    const advanceOnboardStep = () => {
      console.log('advanceOnboardStep', onboardStep, onboardCopy[onboardStep])
      var step = onboardCopy[onboardStep];
      if (step && step.flag) {
        if (step.flag == 'mic') enableMic();
      }

      if(step.actionFn !== undefined) {
        console.log('onboardActionFn', step.actionFn)
        step.actionFn()
        .then(() => {
          setIntroTitle('Meet Steve: Practice Session')
          setUseIntroTitle(true)
          setInterviewStarted(true)
          setTimer(30)
        })
      }
      
      var newStep = onboardStep + 1
      var newProgress = onboardProgress + 25
      var practiceChatEnabled = false
      if(session.practiceChatEnabled) {
        practiceChatEnabled = session.practiceChatEnabled
      }

      if (onboardStep < onboardCopy.length) {
        if(onboardCopy.length -1 >= newStep) {
          // check if practice chat enabled
          console.log('practice chat enabled', practiceChatEnabled, onboardCopy[onboardStep + 1])
          if(onboardCopy[newStep].practiceChat && !practiceChatEnabled) {
            // skip practice chat
            newStep = onboardStep + 3
            newProgress = onboardProgress + 50
          }
        }

        setOnboardStep(newStep)

        step = onboardCopy[newStep];

        const progress = newProgress;
        setOnboardProgress(progress)
        setOnboardPrompt(step.prompt)
        setOnboardNote(step.note)
        setOnboardHeading(step.heading)
        setOnboardAction(step.action)
      } else {
        console.log('end onboarding')
        handleStartInterview(globalInterviewType)
      }
    }

    const handleStartOnboardRetellDemo = () => {
      console.log('handling onboard demo')
      setWaitingDemo(true);

      // add time limit to stop onboard demo convo
      const onboardCallTimeout = 30000;
      setTimeout(() => {
        setWaitingDemo(false);
        handleStopOnboardRetellDemo();
      }, onboardCallTimeout)

      // TODO: retellWebClient.startCall
      // https://docs.retellai.com/make-calls/web-call

    }

    const handleStopOnboardRetellDemo = () => {
      // TODO: sanity check stop call here as timeout stops
      retellWebClient.stopConversation()
    }

    var interviewType = 'system_design_event_ticketing'
    const urlParams = new URLSearchParams(window.location.search);
    const interviewTypeQueryParam = urlParams.get('interview_type');
    var interviewTypeModalText = ''
    if (interviewTypeQueryParam) {
      globalInterviewType = interviewTypeQueryParam;
    }

    // if(!waitlistOpened && timerElapsed >= 2 * 60 ) {    
    //   setWaitlistOpened(true)
    //   setWaitlistIsOpen(true)
    //   handleToggleMute();
    // }

    let current_question = undefined
    if(interview.metadata.interview_config.questions && interview.metadata.interview_config.questions.length > 0) {
      current_question = interview.metadata.interview_config.questions[interview.parrot_text_index]      
    }    

    const renderInterviewScreen = () => {
      return (
        <Container maxWidth={false} disableGutters={true} className = "whiteboardView" style={{ backgroundColor: 'white', color: 'black', margin: 0, width: '100%', display: 'flex', height: '100%', maxHeight: '100%', position: 'relative' }}>       
            <div style={{ height: '100vh', width: '25%', display: 'inline-flex', paddingTop: 50, background: '#F8F8F8' }}>                  
              <div style={{ paddingLeft: 25, paddingBottom: 25, marginRight: 25 }}>
                <a href="https://techinterviewer.ai"><img src={Logo} /></a>
                <div style={{ marginTop: '50px'}}>
                  {!isMobile &&
                    <div style={{fontSize: 22, fontFamily: 'PPNeueMontreal'}}>             
                      {session !== null &&
                        <>
                          {useIntroTitle ?
                            <>
                              {introTitle}
                            </>
                            :
                            <>
                              {session.title}
                            </>                            
                          }
                        </>
                      }                          
                    </div>
                  }
                  </div>
                  <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                  <span style={{ color: '#656565', position: 'fixed', bottom: 25 }}>&copy; Wayfaster 2024</span>
                </div>                                   
                </div>      
                <div style={{ display: 'inline-flex', height: '100%', width: '75%', paddingLeft: 25 }}>
                  <div style={{ width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', borderRadius: 4, background: '#F8F8F8', padding: 25, borderRadius: '10px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 30, fontSize: 16, fontFamily: 'PPNeueMontreal', color:'black' }}>
                        {visualState === '' &&
                          <div style={{ color: interviewStarted ? 'black' : '#F8F8F8' }}>
                            {interviewerName} is Connecting...
                          </div>
                        }
                        {visualState === 'listening' &&
                          <div>
                            {interviewerName} is <b>Listening</b>
                          </div>
                        }
                        {visualState === 'speaking' &&
                          <div>
                              {interviewerName} is <b>Speaking</b>
                          </div>
                        }                          
                        {visualState === 'connected' &&
                          <div>
                              {interviewerName} is <b>Connected</b>
                          </div>
                        }                          
                        {visualState === 'disconnected' &&
                          <div>
                            {interviewerName} has <b>Left the Call</b>
                          </div>
                        }                            
                      </div>
                      {((isMobile && firstMobileScreenDone) || !isMobile) &&
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto', fontFamily: 'PPNeueMontreal', fontSize: 18, color: 'black', position: isMobile ? 'absolute' : 'relative' }}>
                        <AccessTimeIcon />
                        <span style={{ fontSize: 18, marginLeft: 5 }}>
                          {secondsToHumanReadableTime(timer)}
                        </span>
                      </div>                          
                      }
                      {(!isMobile && interview.metadata.interview_config.title.startsWith('System Design')) &&
                        <IconButton onClick={handleSubmitDiagramFeedback} disabled={loadingDiagramFeedback || !interviewStarted} style={{
                          background: 'white',
                          color: 'black',
                          borderRadius: 10,
                          fontFamily: 'PPNeueMontreal',
                          paddingLeft: 20, 
                          paddingRight: 20,
                          fontSize: 16,
                          marginLeft: 'auto', // Added to move the button to the right
                        }}>
                          {loadingDiagramFeedback ? 'Syncing...' : <><SyncIcon />
                          Sync</>}                          
                        </IconButton>
                      }
                      {(interviewStarted && !isMobile) &&
                      <>
                        <IconButton onClick={() => {setShowStopInterviewConfirmation(true)}} style={{
                          background: '#000',
                          color: 'white',
                          borderRadius: 10,
                          fontFamily: 'PPNeueMontreal',
                          paddingLeft: 20, 
                          paddingRight: 20,
                          fontSize: 16,
                          marginLeft: 15, // Added to move the button to the right
                        }} disabled={!interviewStarted}>
                          <StopIcon />
                          Stop Interview
                        </IconButton>                          
                        </>
                      }
                    </div>                                                       
                    <div style={{ height: '80%', marginTop: 25, width: '80%' }}>
                      {(!current_question && (current_question && current_question.type !== 'text_input') && !interview.metadata.interview_config.title.startsWith('System Design')) &&
                        <>
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: '70px' }}>
                            <p style={{ fontFamily: 'PPNeueMontreal', fontSize: 18, }}>Instructions</p>
                          </div>
                          <br/>
                          {renderContext()}
                        </>
                      }
                      {(current_question && current_question.type === 'text_input') &&
                        <div style={{ textAlign:'center'}}>
                          <img src={current_question.metadata.media.url} style={{ width: '1100px' }} />
                          <br/><br/>
                          <textarea 
                            placeholder="Enter your submission attempt here"
                            value={submission}
                            onChange={(e) => {setSubmission(e.target.value)}}
                            style={{ width: '800px', fontFamily: 'PPNeueMontreal', fontSize: 14, height: 200, padding: 10, borderRadius: 10 }}
                          />
                          <br/><br/>
                          <button className="btn" onClick={handleSubmitText} disabled={feedbackPending}>
                            {feedbackPending ? <><CircularProgress size={10} /> &nbsp;&nbsp; Getting Feedback</> : "Submit Attempt"}
                          </button>
                          <br/><br/><br/><br/><br/>
                        </div>
                      }
                        <div className="custom-excalidraw" style={{ height: '80vh'}}>
                          {isMobile &&
                          <>                                                    
                            <div style={{fontSize: 22, fontFamily: 'PPNeueMontreal'}}>             
                              {session !== null &&
                                <>
                                  {useIntroTitle ?
                                    <>
                                      {introTitle}
                                    </>
                                    :
                                    <>
                                      {session.title}
                                    </>                            
                                  }
                                </>
                              }                          
                            </div>           
                            <br/><br/><br/><br/>                                   
                            <IconButton onClick={() => {setShowStopInterviewConfirmation(true)}} style={{
                              background: '#20C58A',
                              color: 'white',
                              borderRadius: 10,
                              fontFamily: 'PPNeueMontreal',
                              paddingLeft: 20, 
                              paddingRight: 20,
                              fontSize: '16px'
                            }} disabled={!interviewStarted}>
                              <StopIcon fontSize="large" />
                              Stop Interview
                            </IconButton>            
                          </>
                          }
                        </div>
                    </div>                          
                    <div dangerouslySetInnerHTML={{ __html: videoHTML }}></div>
                  </div>                           
                </div>                          
        </Container>        
      )
    }

    const renderPracticeChatInterviewScreen = () => {
      return (
        <Container 
          maxWidth={false} 
          disableGutters={true} 
          sx={{
            backgroundColor: 'white', 
            color: 'black', 
            m: 0, 
            margin: 'auto',
            textAlign: 'center',
            width: isMobile ? '90%' : '100%', 
            display: 'flex', 
            height: '100%', 
            maxHeight: '100%', 
            position: 'relative'
          }}
        >       
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: mediaQuery ? 'column' : 'row',
              alignItems: 'center', 
              borderRadius: 1, 
              p: 3, 
              borderBottom: '1px solid #F8F8F8'
            }}>
              {session !== null &&
                <Typography 
                  sx={{ 
                    fontSize: '18px', 
                    fontFamily: 'PPNeueMontreal', 
                    fontWeight: 600,
                    mb: mediaQuery ? 2 : 0
                  }}
                >
                  {useIntroTitle ? introTitle : session.title}
                </Typography>
              }                  
              {((isMobile && firstMobileScreenDone) || !isMobile) &&
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  m: mediaQuery ? '1rem 0' : '0 auto', 
                  fontFamily: 'PPNeueMontreal', 
                  fontSize: 18, 
                  color: 'black', 
                  position: isMobile ? 'static' : 'relative'
                }}>
                  <AccessTimeIcon />
                  <Typography sx={{ fontSize: 18, ml: 0.5 }}>
                    {secondsToHumanReadableTime(timer)}
                  </Typography>
                </Box>                          
              }
              {interviewStarted &&
                <>
                  {introDone ?
                    <IconButton onClick={() => {setShowStopInterviewConfirmation(true)}} style={{
                      background: '#000',
                      color: 'white',
                      borderRadius: 10,
                      fontFamily: 'PPNeueMontreal',
                      paddingLeft: 20, 
                      paddingRight: 20,
                      fontSize: 16,
                      marginLeft: 15, // Added to move the button to the right
                    }} disabled={!interviewStarted}>
                      <StopIcon />
                      Stop Interview
                    </IconButton>                          
                  :                              
                    <IconButton 
                      onClick={() => {
                        setUseIntroTitle(false)
                        setInterviewStarted(false)
                        retellWebClient.stopCall()
                        setTimer(session.length * 60)                                    
                        advanceOnboardStep()
                        setVisualState('')
                        introDone = true                                 
                      }} 
                      sx={{
                        bgcolor: 'black',
                        color: 'white',
                        borderRadius: 2,
                        fontFamily: 'PPNeueMontreal',
                        px: 2.5,
                        fontSize: 16,
                        ml: 2,
                      }} 
                      disabled={!interviewStarted}
                    >
                      <StopIcon />
                      Skip chat & continue
                    </IconButton>                                                      
                  }
                </>
              }
            </Box>                                                       
            <Box sx={{ height: '80%', mt: 3, width: mediaQuery ? '90%' : '100%', px: mediaQuery ? 2 : 0 }}>
              {(!current_question && (current_question && current_question.type !== 'text_input') && !interview.metadata.interview_config.title.startsWith('System Design')) &&
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: mediaQuery ? 4 : 9 }}>
                    <Typography sx={{ fontFamily: 'PPNeueMontreal', fontSize: 18 }}>Instructions</Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    {renderContext()}
                  </Box>
                </>
              }
              {(current_question && current_question.type === 'text_input') &&
                <Box sx={{ textAlign: 'center' }}>
                  <Box 
                    component="img" 
                    src={current_question.metadata.media.url} 
                    sx={{ width: '100%', maxWidth: '1100px' }} 
                  />
                  <textarea 
                    placeholder="Enter your submission attempt here"
                    value={submission}
                    onChange={(e) => {setSubmission(e.target.value)}}
                    style={{ 
                      width: '100%', 
                      maxWidth: '800px', 
                      fontFamily: 'PPNeueMontreal', 
                      fontSize: 14, 
                      height: 200, 
                      padding: 10, 
                      borderRadius: 10,
                      marginTop: '1rem',
                      marginBottom: '1rem'
                    }}
                  />
                  <Button 
                    variant="contained" 
                    onClick={handleSubmitText} 
                    disabled={feedbackPending}
                    sx={{ mt: 2, mb: 4 }}
                  >
                    {feedbackPending ? <><CircularProgress size={20} /> &nbsp;&nbsp; Getting Feedback</> : "Submit Attempt"}
                  </Button>
                </Box>
              }
              {interview.metadata.interview_config.title.startsWith('System Design') &&
                <>
                  <Typography sx={{ mb: 2 }}>Draw in the Whiteboard and hit "Sync" in the top right to get any thoughts across.</Typography>
                  <Box sx={{ height: '80vh'}}>
                    <Excalidraw 
                      theme="light"
                      excalidrawAPI={(api)=> setExcalidrawAPI(api)}                       
                    />
                  </Box>
                </>   
              }
    
            <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
              {visualState === '' &&
                <div style={{ 
                  fontSize: isMobile ? '20px' : '28px', 
                  fontWeight: 600, 
                  marginTop: isMobile ? '2rem' : '25vh' 
                }}>
                  {interviewerName} is Connecting...
                </div>
              }
              {visualState === 'listening' &&
                <>
                  <div style={{ 
                    fontSize: isMobile ? '20px' : '28px', 
                    fontWeight: 600, 
                    marginTop: isMobile ? '2rem' : '25vh' 
                  }}>
                    {interviewerName} is <b>Listening</b>
                  </div>
                  <img 
                    src={Listening} 
                    style={{ 
                      marginTop: '1rem', 
                      width: isMobile ? '80%' : 'auto', 
                      maxWidth: '200px' 
                    }} 
                  />
                </>
              }
              {visualState === 'speaking' &&
                <>
                  <div style={{ 
                    fontSize: isMobile ? '20px' : '28px', 
                    fontWeight: 600, 
                    marginTop: isMobile ? '2rem' : '25vh' 
                  }}>
                    {interviewerName} is <b>Speaking</b>
                  </div>
                  <img 
                    src={Listening} 
                    style={{ 
                      marginTop: '1rem', 
                      width: isMobile ? '80%' : 'auto', 
                      maxWidth: '200px' 
                    }} 
                  />
                </>
              }                          
              {visualState === 'connected' &&
                <div style={{ 
                  fontSize: isMobile ? '20px' : '28px', 
                  fontWeight: 600, 
                  marginTop: isMobile ? '2rem' : '25vh' 
                }}>
                  {interviewerName} is <b>Connected</b>
                </div>
              }                          
              {visualState === 'disconnected' &&
                <div style={{ 
                  fontSize: isMobile ? '20px' : '28px', 
                  fontWeight: 600, 
                  marginTop: isMobile ? '2rem' : '25vh' 
                }}>
                  {interviewerName} has <b>Left the Call</b>
                </div>
              }                            
            </div>                          
            <Box dangerouslySetInnerHTML={{ __html: videoHTML }}></Box>
          </Box>     
          </Box>                     
        </Container>        
      )
    }

    return (
        <>
            {renderInterviewScreen()}
            <Dialog open={showStopInterviewConfirmation}>
              <DialogTitle>
                Stop Interview Confirmation
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to end the interview? 
                  <br/><br/>
                  This means we will only evaluate you based on your partial input thus far and not on the full interview. This may impact your interview performance and evaluation for the given role.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <button className="btn" onClick={() => {setShowStopInterviewConfirmation(false)}}>
                  No, Resume.
                </button>
                <button className="btn danger" onClick={() => {
                  setShowStopInterviewConfirmation(false)
                  handleStopRetellInterview(true)}
                }>
                  Yes, Stop.
                </button>
              </DialogActions>              
            </Dialog>


            <Dialog open={showGracefulExitMsg}>
              <DialogTitle>
                Interview Complete
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  The interview has ended. Thank you so much for taking the time to interview with us. We will be getting back to you shortly on next steps. You can now exit this window.
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Dialog open={showInterviewEndedMidway}>
              <DialogTitle>
                Interview Stopped before Completion
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  The interview was ended before completion. We can only evaluate you based on your partial input thus far. This may impact your interview performance and evaluation for the given role. You may now exit this window.
                </DialogContentText>
              </DialogContent>
            </Dialog>

            {/* Onboarding / Pre-interview */}
            {!closeOnboarding && 
            <Dialog 
              open={!closeOnboarding} 
              fullScreen={mediaQuery}
              maxWidth={mediaQuery ? "sm": "lg"} 
              fullWidth
            >                
              <OnboardLinearProgress 
                variant="determinate" 
                value={onboardProgress} 
                style={{ margin: '8px' }}
              />
              <DialogContent style={{
                backgroundColor: 'white',
                color: 'black',
                border: '0.5px solid #E9E9E9',
                borderRadius: 6,
                padding: 16,
                height: mediaQuery ? '100vh' : '80vh',
                display: 'flex',
                flexDirection: 'column',
              }}>
                <DialogContentText component="div" style={{ 
                  display: "flex", 
                  flexDirection: "column", 
                  height: '100%', 
                  width: '100%',
                  color: 'inherit',
                }}>
                  {sessionLoading ? (
                    <div style={{ margin: 'auto', textAlign: 'center', fontSize: '18px', fontWeight: 600 }}>
                      Loading session <CircularProgress size={20} style={{ marginLeft: '10px' }} />
                    </div>
                  ) : (!session || Object.keys(session).length === 0) ? (
                    <div style={{ margin: 'auto', textAlign: 'center', fontSize: '18px', fontWeight: 600 }}>
                      No session found. Please try again and ensure this is a valid session page.
                    </div>
                  ) : (
                  <>
                    {onboardCopy[onboardStep].interviewScreen ?
                      renderPracticeChatInterviewScreen()
                    :
                      <div style={{ 
                        textAlign: 'center', 
                        margin: 'auto', 
                        maxWidth: '100%',
                        width: 'auto',
                        padding: '16px',
                      }}>
                        <div style={{ marginBottom: '16px' }}>{onboardHeading}</div>
                        <div style={{ marginBottom: '16px' }}>{onboardPrompt}</div>
                        {onboardNote &&
                          <div style={{ marginBottom: '16px' }}>{onboardNote}</div>
                        }
                        <div>{renderLetsGoBtn()}</div>
                      </div>
                    }

                    {onboardStep === 2 && micEnabled && 
                      <CheckCircleIcon style={{ fontSize: '20px', color: '#1976d2', margin: '8px' }} />
                    }

                    {onboardStep === 3 && waitingDemo &&
                      <img 
                        src={Soundbar} 
                        alt="Sound visualization" 
                        style={{
                          height: '24px',
                          marginTop: '24px',
                          marginRight: 'auto',
                        }} 
                      />
                    }                    
                  </>
                  )}                    
                </DialogContentText>
              </DialogContent>
            </Dialog>
          }
        </>
    );
};

export default App;